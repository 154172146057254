import { Injectable, inject } from '@angular/core';
import { AlertService } from '@core/services/alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, concatMap } from 'rxjs/operators';
import { ItemCatalogueExpedienteCar } from '@expediente/models/expediente-car.model';
import { ExpedienteApiActions } from '.';
import { ExpedienteService } from '../services/expediente.service';

@Injectable()
export class ExpedienteEffects {
  private readonly _alertService = inject(AlertService);
  private readonly _expedienteCar = inject(ExpedienteService);

  constructor(private actions$: Actions) {}

  GET_CATALOGUE_EXPEDIENTE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpedienteApiActions.initCatalogueExpedienteDocs),
      concatMap(() =>
        this._expedienteCar.getCatalogue().pipe(
          map((response) => {
            let catalogueDocumentsExpendediente: ItemCatalogueExpedienteCar[] = [];

            if (response.code === 200 && response.result.length > 0)
              catalogueDocumentsExpendediente = response.result.map((doc) => ({
                ...doc,
                tipo_operacion: doc.tipo_operacion ? JSON.parse(doc.tipo_operacion) : [],
                tipo_transaccion: doc.tipo_transaccion ? JSON.parse(doc.tipo_transaccion) : [],
                etapa: doc.etapa ? JSON.parse(doc.etapa) : [],
                origen: doc.origen ? JSON.parse(doc.origen) : [],
                fileSaved: [],
              }));

            return ExpedienteApiActions.loadSuccessCatalogueExpedienteDocs({ catalogueDocumentsExpendediente });
          }),
          catchError(async () =>
            ExpedienteApiActions.loadErrorCatalogueExpedienteDocs({
              errorMessage: 'Error al cargar catálogo de expediente, informe al administrador',
            })
          )
        )
      )
    );
  });

  NOTIFY_ERROR$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpedienteApiActions.loadErrorCatalogueExpedienteDocs),
        map((action) => {
          this._alertService.error(action.errorMessage);
        })
      );
    },
    { dispatch: false }
  );
}
