import { createReducer, on } from '@ngrx/store';
import { ExpedienteApiActions } from '.';
import { FilterDocumentCatalogue, ItemCatalogueExpedienteCar } from '@core/models';

export interface ExpedienteState {
  catalogueDocumentsExpendediente?: ItemCatalogueExpedienteCar[];
  filterDocuments?: FilterDocumentCatalogue;
}

const initialState: ExpedienteState = {
  catalogueDocumentsExpendediente: [],
  filterDocuments: null,
};

export const stateExpedienteReducer = createReducer<ExpedienteState>(
  initialState,
  on(
    ExpedienteApiActions.loadSuccessCatalogueExpedienteDocs,
    (state, action): ExpedienteState => ({
      ...state,
      catalogueDocumentsExpendediente: action.catalogueDocumentsExpendediente,
    })
  ),
  on(
    ExpedienteApiActions.loadFilterDocuments,
    (state, action): ExpedienteState => ({
      ...state,
      filterDocuments: action.filterDocuments,
    })
  )
);
