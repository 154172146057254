import { FilterDocumentCatalogue, ItemCatalogueExpedienteCar } from '@expediente/models/expediente-car.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const ExpedienteApiActions = createActionGroup({
  source: 'Catalogue expediente API',
  events: {
    initCatalogueExpedienteDocs: emptyProps(),
    loadSuccessCatalogueExpedienteDocs: props<{ catalogueDocumentsExpendediente: ItemCatalogueExpedienteCar[] }>(),
    loadErrorCatalogueExpedienteDocs: props<{ errorMessage: string }>(),
    loadFilterDocuments: props<{ filterDocuments: FilterDocumentCatalogue }>(),
    resetStore: emptyProps(),
  },
});

export const {
  initCatalogueExpedienteDocs,
  loadSuccessCatalogueExpedienteDocs,
  loadErrorCatalogueExpedienteDocs,
  loadFilterDocuments,
  resetStore,
} = ExpedienteApiActions;
